<template>
  <b-card
    class="border"
    no-body
  >
    <b-card-body>
      <h5 class="mb-0 pb-0">
        <feather-icon
          icon="LockIcon"
          size="18"
        />
        Edit Permissions
      </h5>
    </b-card-body>

    <b-table
      striped
      responsive
      :items="permissionsTable"
      class="mb-0"
    >

      <template #cell(contentType)="data">
        {{ data.value }}
      </template>
      <template #cell()="data">
        <b-form-checkbox
          :id="data.value"
          v-model="checkedNames"
          :value="data.value"
        />
      </template>

    </b-table>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BBadge, BTable, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import globalLists from '../../libs/global-lists'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BTable,
    BFormCheckbox,
  },
  model: {
    prop: 'list',
    event: 'listchange',
  },
  props: {
    list: {
      type: String,
      required: true,
    },
  },
  computed: {
    checkedNames: {
      get() {
        return this.list.split(',')
      },
      set(value) {
        this.$emit('listchange', value.join(','))
      },
    },
  },
  setup() {
    const {
      contentTypes,
    } = globalLists()

    const permissionsTable = contentTypes.map(row => ({
      contentType: row.label,
      canView: row.value,
    }))

    return {
      permissionsTable,

    }
  },
}
</script>

<style>

</style>
