<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="8"
      >
        <!-- Permission Info: Input Fields -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <b-form
            autocomplete="off"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <b-row>

              <!-- Field: Permission name -->
              <b-col
                cols="12"
              >
                <validation-provider
                  #default="validationContext"
                  name="Permission Name"
                  rules="required|min:3|max:100|regex:^([a-zA-Z\-]+ )*[a-zA-Z\-]+$"
                >
                  <b-form-group
                    label="Permission Name"
                    label-for="name"
                  >
                    <b-form-input
                      id="name"
                      v-model="permissionData.name"
                      autofocus
                      :state="getValidationState(validationContext)"
                      trim
                      placeholder=""
                      autocomplete="off"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- Field: Status -->
              <b-col
                cols="12"
              >
                <validation-provider
                  #default="validationContext"
                  name="Status"
                  rules="required"
                >
                  <b-form-group
                    label="Status"
                    label-for="org-status"
                  >
                    <v-select
                      v-model="permissionData.enabled"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="statusOptions"
                      :state="getValidationState(validationContext)"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="org-status"
                    />

                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- Field: Description -->
              <b-col
                cols="12"
              >
                <validation-provider
                  #default="validationContext"
                  name="Description"
                >
                  <b-form-group
                    label="Description"
                    label-for="description"
                  >
                    <b-form-textarea
                      id="description"
                      v-model="permissionData.description"
                      :state="getValidationState(validationContext)"
                      rows="4"
                      trim
                      placeholder=""
                      autocomplete="off"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

            </b-row>
            <!-- Action Buttons -->
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              type="submit"
            >
              Save Changes
            </b-button>
            <b-button
              variant="outline-secondary"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              :to="{ name: 'permissions-view', params: { id: permissionData.id } }"
            >
              Cancel
            </b-button>
          </b-form>
        </validation-observer>
      </b-col>
      <b-col>
        <!-- PERMISSION TABLE -->
        <permissions-groups-edit-card
          v-model="permissionData.allowed_content_types"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton, BRow, BCol, BFormGroup, BFormInput, BFormInvalidFeedback, BForm, BFormTextarea, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import router from '@/router'
import store from '@/store'
import usePermissionsList from '../permissions-list/usePermissionsList'
import PermissionsGroupsEditCard from '../../../layouts/components/PermissionGroupsEditCard.vue'


export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BForm,
    BFormTextarea,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    PermissionsGroupsEditCard,

    // Form Validation
    ValidationProvider,
    ValidationObserver,

    vSelect,
  },
  props: {
    permissionData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { resolvePermissionRoleVariant, statusOptions } = usePermissionsList()


    const onSubmit = () => {
      store.dispatch('app/updatePermission', { id: router.currentRoute.params.id, permissionData: props.permissionData })
        .then(() => {
          router.push({ name: 'permissions-view', params: { id: router.currentRoute.params.id } })
        })
        .catch(error => {
          if(typeof error.response === 'undefined') {
            //log error
            console.error(error)
          } else {
            //alert user to error
            toast({
              component: ToastificationContent,
              props: {
                title: 'Error creating new permission.',
                text: 'ERROR: ' + error.response.data.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
            console.log(error.response.data.message)
          }
        })
    }

    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      resolvePermissionRoleVariant,
      avatarText,
      statusOptions,

      // form
      onSubmit,
      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
