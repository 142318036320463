<template>
  <component :is="permissionData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="permissionData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching permission data
      </h4>
      <div class="alert-body">
        No permission found with this permission id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-permissions-list'}"
        >
          Permission List
        </b-link>
        for other permissions.
      </div>
    </b-alert>
    <permission-edit-tab-account
      v-if="permissionData"
      :permission-data="permissionData"
    />
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import PermissionEditTabAccount from './PermissionEditTabAccount.vue'


export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    PermissionEditTabAccount,

  },
  setup() {
    const permissionData = ref(null)

    store.dispatch('app/fetchPermission', { id: router.currentRoute.params.id })
      .then(response => {
        permissionData.value = response.data.results[0]
        if (permissionData.value.allowed_content_types == null) permissionData.value.allowed_content_types = ''

      })
      .catch(error => {
        if (error.response.status === 404) {
          permissionData.value = undefined
        }
      })

    return {
      permissionData,
    }
  },
}
</script>

<style>

</style>
